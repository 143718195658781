import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import CommentsConciliation from './CommentsConciliation.jsx'


const RejectConciliationModal = (
  {
    showRejectConciliationModal,
    setShowRejectConciliationModal,
    comments,
    setComments,
    formAlerts,
    handleFinishRejectConciliationBtnClick,
    title
  }
) => {

  const handleClose = () => setShowRejectConciliationModal(false)
  const [showRejectFinishBtn, setShowRejectFinishBtn] = useState(true)


  useEffect(() => {
    if (comments.length > 0) {
      setShowRejectFinishBtn(false)
    } else {
      setShowRejectFinishBtn(true)
    }
  }, [comments])


  return (
    <>
      <Modal show={showRejectConciliationModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <button type={'button'} className={'close'} onClick={handleClose}>
            <span aria-hidden={'true'}>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <CommentsConciliation
            {...
              {
                comments,
                setComments,
                formAlerts
              }
            }
          />
        </Modal.Body>
        <Modal.Footer className={'modal-footer justify-content-end'}>
          <div
            className={showRejectFinishBtn ? 'btn btn-primary disabled pe-none' : 'btn btn-primary'}
            onClick={showRejectFinishBtn ? null : handleFinishRejectConciliationBtnClick}
          >
            Terminar
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RejectConciliationModal