import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import CommentsConciliation from './CommentsConciliation'
import BackgroundConciliationItems from './BackgroundConciliationItems.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const BackgroundConciliationModal = (
  {
    initialBackgrounds,
    backgrounds,
    setBackgrounds,
    showBackgroundsConciliationModal,
    setShowBackgroundsConciliationModal,
    comments,
    setComments,
    formAlerts,
    backgroundConciliationOptions,
    handleFinishBackgroundConciliationBtnClick,
    removedBackgrounds,
    setRemovedBackgrounds,
    title
  }
) => {

  const handleClose = () => setShowBackgroundsConciliationModal(false)
  const [showFinishBtn, setShowFinishBtn] = useState(true)

  const initialBackground = () => {
    return {
      id: null,
      reject_detail_id: null,
      preceding_id: backgroundConciliationOptions[0].id,
      details: ''
    }
  }

  useEffect(() => {
    if (comments.length > 0) {
      setShowFinishBtn(false)
    } else {
      setShowFinishBtn(true)
    }
  }, [comments])

  const handleAddBackgroundBtnClick = () => {
    addBackground(initialBackground())
    if (comments.length > 0) {
      setShowFinishBtn(false)
    }
  }

  const addBackground = (background) => {
    setBackgrounds([...backgrounds, background])
  }


  return (
    <>
      <Modal show={showBackgroundsConciliationModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <button type={'button'} className={'close'} onClick={handleClose}>
            <span aria-hidden={'true'}>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <BackgroundConciliationItems
            {...
              {
                initialBackgrounds,
                backgrounds,
                setBackgrounds,
                backgroundConciliationOptions,
                removedBackgrounds,
                setRemovedBackgrounds,
              }
            }
          />
          <CommentsConciliation
            {...
              {
                comments,
                setComments,
                formAlerts
              }
            }
          />
        </Modal.Body>
        <Modal.Footer className={'modal-footer justify-content-end'}>
          <div
            className={'btn btn-success'}
            onClick={handleAddBackgroundBtnClick}
          >
            <FontAwesomeIcon icon={faPlus} className={'icon-upload-alt'} /> Agregar antecedente de conciliación
          </div>
          <div
            className={showFinishBtn ? 'btn btn-primary disabled pe-none' : 'btn btn-primary'}
            onClick={showFinishBtn ? null : handleFinishBackgroundConciliationBtnClick}
          >
            Terminar
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default BackgroundConciliationModal
