import React  from 'react'
import getInputErrorClass from '../utils/getInputErrorClass'
import Textarea from './Textarea'

const CommentsConciliation = (
  {
    comments,
    setComments,
    formAlerts,
    placeholder
  }
) => {

  const handleCommentsChange = (event) => {
    setComments(event.target.value)
  }

  const getPlaceholder = () => {
    return placeholder === undefined ? 'Obligatorio' : placeholder
  }

  return (
    <>
      <div className={'form-row justify-content-center'}>
        <div className={'col-12'}>
          <Textarea
            placeholder={getPlaceholder()}
            value={comments}
            onChange={handleCommentsChange}
            className={getInputErrorClass(formAlerts, 'comments')}
          />
        </div>
      </div>
    </>
  )
}

export default CommentsConciliation