import React from 'react'
import FormActionButton from '../../../../shared/FormActionButton'
import {
  faCheck,
  faMailReplyAll
} from '@fortawesome/free-solid-svg-icons'

const ConciliationFooter = (
  {
    requirement,
    setShowBackgroundsConciliationModal,
    setShowRejectConciliationModal,
    approveRequirementConcilation,
    isFullInsurer
  }
) => {


  const handleApproveBtnClick = () => {
    // Proceder a llamar el endpoint de conciliación
    approveRequirementConcilation()
  }

  const handleRejectBackgroundBtnClick = () => {
    // Mostramos modal para ingresar comentario y antecedentes
    setShowBackgroundsConciliationModal(true)
  }

  const handleRejectBtnClick = () => {
    // Mostramos modal para ingresar comentario
    setShowRejectConciliationModal(true)
  }


  return (
    <>
      {!requirement.forbiddenPolicy && (
        <div className={'form-action-buttons d-flex justify-content-center align-items-center w-100 border border-white rounded p-3 bg-white mt-3'}>
          { !isFullInsurer && (
            <>
              <FormActionButton
                title={requirement.canApprove ? 'Aprobar' : 'Solicitar aprobación'}
                className={'btn-primary' + (requirement.unlockApprove ? ' unlockable' : '')}
                onClick={handleApproveBtnClick}
                content={'Aprobar'}
                icon={faCheck}
              />
              <FormActionButton
                title={'Falta de Antecedentes'}
                className={'btn-danger'}
                onClick={handleRejectBackgroundBtnClick}
                content={'Falta de Antecedentes'}
                icon={faMailReplyAll}
              />
              <FormActionButton
                title={'Editar'}
                className={'btn-danger'}
                onClick={handleRejectBtnClick}
                content={'Rechazar'}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ConciliationFooter
